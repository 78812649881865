import React from 'react';

import Privacy from 'components/docs/Privacy';
import PageTitleBar from 'components/misc/PageTitleBar';

const PrivacyPage = () => (
  <div>
    <PageTitleBar
      title="개인정보처리방침"
      titleLangKor
      backgroundImage={require('../assets/images/banner/agreement.jpg')}
    />
    <Privacy />
  </div>
);

export default PrivacyPage;
